import React from "react"
import { navigate } from "gatsby"

import Layout from "../../layouts"

import { getActiveCodeItemList } from "../../api/item"
import "./sell-active-code.css"

class SellActiveCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItemList: [],
    }
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    const res = await getActiveCodeItemList()
    if (res.data.code === "0000") {
      this.setState({ activeItemList: res.data.data })
    }
  }

  render() {
    const { activeItemList } = this.state
    console.log(activeItemList)
    return (
      <Layout isSpacerDisplay={true} title="激活码购买">
        <div className="mdnice-membership-container">
          <div className="mdnice-membership-table-container">
            <div className="mdnice-membership-sidebar">
              <div
                className="mdnice-membership-sidebar-row"
                onClick={() => navigate("/membership/product")}
              >
                <p>产品功能</p>
              </div>
              <div
                className="mdnice-membership-sidebar-row-active"
                onClick={() => navigate("/membership/sell-active-code")}
              >
                <p>激活码购买</p>
              </div>
            </div>

            {activeItemList.map(item => (
              <div
                className="mdnice-sell-active-code-coloum"
                key={item.name}
                onClick={() => {
                  navigate(`/items?id=${item.itemId}&type=activeCode`)
                }}
              >
                <div className="mdnice-sell-active-code-row">
                  <p className="mdnice-sell-active-code-name">{item.name}</p>
                  <p className="mdnice-sell-active-code-price">
                    <span>¥</span>
                    {item.price / 100}
                  </p>
                  <p className="mdnice-sell-active-code-per-price ">
                    ¥{(item.price / 100 / item.duration).toFixed(2)}/天
                  </p>
                  <p className="mdnice-sell-active-code-device ">
                    支持 {item.deviceNum} 台设备
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default SellActiveCode
